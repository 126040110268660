import * as React from "react";

import AboutUs from 'src/containers/5c-aboutUs/aboutUs';

export default (props: any) => {
  return <AboutUs {...props} />;
}

export const query = graphql`
  query AboutUsQuery {
    allWordpressWpStaff {
      edges {
        node {
          title
          acf {
            description
            description_sc
            role
            role_sc
          }
          featured_media {
            localFile {
              url
            }
          }
        }
      }
    }
  }
`