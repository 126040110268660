// Module
import * as React from "react";
// Helper function
// import { nameToPathConvert } from "src/helper/helper";

import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import hoistStatics from 'hoist-non-react-statics';

// Components
import Subscription from "src/components/Organisms/Subscription/subscription";
// import CustomLink from "src/components/Atoms/CustomLink/customLink";

// Style
import "./aboutUs.scss";

// List data
import { testimonial } from "./data";
import Hero, {
    headerStyle,
    IHeroProps
} from "src/components/Organisms/Hero/hero";
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next';
import { Component } from "react";
import i18next, { t } from "i18next";
// const { t } = useTranslation('translation');

// const infoArrObj = i18next.t('info', { returnObjects: true } ) as unknown as { name : any }
// const infoArr = (infoArrObj as unknown as any[]).map(( e ) => { return e } )

interface IProps extends WithTranslation {
    prop: any;
}

interface IState {
    state: any;
}

const Team = (props: any) => {
    const { i18n } = useTranslation();
    
    return (
        <div className="container-fluid section-padding-tb96">
            <div className="container">
                <div className="row">
                    {props.list.map((unit: any, keyIndex: any) => (
                        <div
                            className={
                                "member-card p-3 " +
                                (keyIndex < 2
                                    ? "col-sm-12 col-md-6"
                                    : "col-sm-6 col-md-4")
                            }
                        >
                            <img
                                className="card-img-top rounded-circle img-fluid w-25"
                                src={unit.url}
                                alt={unit.name}
                            />
                            <div className="member-card__body">
                                <h4>{unit.name}</h4>
                                <small>{i18n.language === 'sc' ? unit.role_sc : unit.role}</small>
                            </div>
                            <div className="member-card__footer">
                                {i18n.language === 'sc' ? unit.description_sc : unit.description}
                                {/* {this?.props.t('description')} */}
                                {/* <CustomLink
                                    link={
                                        "/about-us/" + nameToPathConvert(unit.name)
                                    }
                                    variation="default"
                                /> */}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}



const Testimonials = (props: any) => {
    return (
        <div className="container-fluid section-padding-tb96">
            <div className="container">
                <h2 className="section-header all-cap">
                    {t(`intro.client.title`)}{" "}
                    <bdi className="text-highlight">{t(`intro.client.subTitle`)}</bdi>
                </h2>
                <div className="card-columns">
                    {props.list.map((person: any, keyIndex: number) => (
                        <div
                            className="card bg-light shadow-sm border-0"
                            key={keyIndex}
                        >
                            <div className="card-body">
                                <div className="media">
                                    <img
                                        className="w-25 rounded-circle align-self-center"
                                        src={person.img}
                                        alt={person.name}
                                    />
                                    <div className="media-body align-self-center ml-2">
                                        <h4 className="overline-header-bold card-title">
                                            {person.name}
                                        </h4>
                                        <small className="">
                                        {t(`intro.client.information.${person.name.replace(' ', '-')}.title`)}
                                        </small>
                                    </div>
                                </div>
                                <p className="mt-3 body-short-texts">
                                    {t(`intro.client.information.${person.name.replace(' ', '-')}.description`)}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

// const List = () => (
//     <div className="container-fluid section-padding-tb96">
//         <div className="container">
//             <div className="row">
//                 <div className="border-left border-warning col-sm-6 col-md-3">
//                     <h2 className="text-highlight">2.2K+</h2>
//                     <h4>Audit Reports</h4>
//                 </div>
//                 <div className="border-left border-warning col-sm-6 col-md-3">
//                     <h2 className="text-highlight">1.5K+</h2>
//                     <h4>Incorporated Companies</h4>
//                 </div>
//                 <div className="border-left border-warning col-sm-6 col-md-3">
//                     <h2 className="text-highlight">1K+</h2>
//                     <h4>China Visa Application</h4>
//                 </div>
//                 <div className="border-left border-warning col-sm-6 col-md-3">
//                     <h2 className="text-highlight">15K+</h2>
//                     <h4>Incorporated Companies</h4>
//                 </div>
//             </div>
//         </div>
//     </div>
// );

// Taken from components/hero
const heroProps: IHeroProps = {
    style: headerStyle(require("src/components/Organisms/images/banner/aboutus.jpg")),
    header: "TEAM CENTRE O",
    subLine:
        "From incorporating business to applying for visa, we have specialists, tools and relationships to take care of our clients. Our mission is to help entrepreneures focus on growing their businesses without getting distracted."
};

// export const getServerSideProps = async ({ locale }:{ locale:any }) => ({
//     props: {
//         ...(await serverSideTranslations(locale, ['common']))
//     }
// });




class AboutUs extends Component<IProps , IState> {
    public render() {
        const { t } = this.props as any;
        // const { t } = useTranslation('info')
        console.log(this.props)
        // console.log(infoArr);
        // console.log(infoArr[0].name)
        return (
            <>
                <Hero {...heroProps} />
                <Team list={this.props.data.allWordpressWpStaff.edges.map(e => ({name: e.title, ...e.node.acf, ...e.node.featured_media.localFile}))} />
                <Testimonials list={testimonial} />
                {/* <List /> */}
                <Subscription />
            </>
        );
    }
}


export default withTranslation('info')(AboutUs)
